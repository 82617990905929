.p-container {
  height: min-content;
  margin: 0px 3vw 0px 3vw; 
  padding-top: 6vh;
}

.p-img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
}

.p-desc {
  margin-top: 4vh;
  padding-bottom: 3vh;
  border-bottom: 2px solid var(--black);
}

.p-year {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.p-btn {
  float: right;
}

.p-list-row {
  border-bottom: 2px solid var(--black);
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
}

.p-list-row-first {
  border-top: 2px solid var(--black);
}

.p-list-title > a {
  text-decoration: none;
  color: var(--black);
}

.p-list-title > a:hover {
  color: var(--gray);
}

.p-list-date {
  font-family: 'NeueMontreal';
  font-style: normal;
  font-weight: 400;
  font-size: 1.4vh;

  margin-left: 18px;

  color: var(--lightgray);

}

.p-list-btn {
  display: flex;
  justify-content: flex-end;
  font-size: 1.4vh;
}
.p-list-btn > a {
  text-decoration: none;
  color: var(--black);
}
.p-list-btn > a:hover {
  color: var(--gray);
}